import React from 'react';

const Profile = React.lazy(() => import('../pages/Profile'));
const Cadastro = React.lazy(() => import('../pages/UserVeiculoCadastro'));
const Cadastro2 = React.lazy(() => import('../pages/UserVeiculoCadastro2'));
const Cadastro3 = React.lazy(() => import('../pages/UserVeiculoCadastro3'));
const Cadastro3Videos = React.lazy(() => import('../pages/UserVeiculoCadastro3Videos'));
const Cadastro4 = React.lazy(() => import('../pages/UserVeiculoCadastro4'));
const Cadastro5 = React.lazy(() => import('../pages/UserVeiculoCadastro5'));
const UserMeusCadastros = React.lazy(() => import('../pages/UserMeusCadastros'));
const UserQueroComprar = React.lazy(() => import('../pages/UserQueroComprar'));
const UserVeiculosLojistas = React.lazy(() => import('../pages/UserVeiculosLojistas'));
const UserVeiculosCarroZero = React.lazy(() => import('../pages/UserVeiculosCarroZero'));
const UserFavorito = React.lazy(() => import('../pages/UserFavorito'));
const UserNotifications = React.lazy(() => import('../pages/UserNotifications'));
const UserChat = React.lazy(() => import('../pages/UserChat'));
const UserChatSuporte = React.lazy(() => import('../pages/UserChatSuporte'));

const routes = [
  {
    path: '/admin-usuario',
    name: 'Profile',
    Component: Profile,
  },
  {
    path: '/admin-usuario/cadastro',
    Component: Cadastro,
  },
  {
    path: '/admin-usuario/cadastro/:id',
    Component: Cadastro,
  },
  {
    path: '/admin-usuario/cadastro2/:id',
    Component: Cadastro2,
  },
  {
    path: '/admin-usuario/cadastro3/:id',
    Component: Cadastro3,
  },
  {
    path: '/admin-usuario/cadastro3-videos/:id',
    Component: Cadastro3Videos,
  },
  {
    path: '/admin-usuario/cadastro4/:id',
    Component: Cadastro4,
  },
  {
    path: '/admin-usuario/cadastro5/:id',
    Component: Cadastro5,
  },
  {
    path: '/admin-usuario/meus-cadastros',
    Component: UserMeusCadastros,
  },
  {
    path: '/admin-usuario/quero-comprar',
    Component: UserQueroComprar,
  },
  {
    path: '/admin-usuario/repasse',
    Component: UserVeiculosLojistas,
  },
  {
    path: '/admin-usuario/mesa',
    Component: UserVeiculosCarroZero,
  },
  {
    path: '/admin-usuario/favoritos',
    Component: UserFavorito,
  },
  {
    path: '/admin-usuario/notificacoes',
    Component: UserNotifications,
  },
  {
    path: '/usuario/chat',
    Component: UserChat,
  },
  {
    path: '/usuario/chat/suporte',
    Component: UserChatSuporte,
  },
];

export default routes;

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import suporteRoutes from './app.suporte.nav.routes';
import adminRoutes from './app.user.nav.routes';
import authRoutes from './auth.nav.routes';

// Pages

function AppSuporteRoutes() {
  return (
    <Routes>
      {adminRoutes.map(({ path, Component }, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}
      {suporteRoutes.map(({ path, Component }, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}
      {authRoutes.map(({ path, Component }, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}
    </Routes>
  );
}

export default AppSuporteRoutes;

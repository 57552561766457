/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { useAuth } from './app/hooks/auth';
import ManagerProvider from './app/hooks/manager';
import AppAdminRoutes from './app/routes/app.admin.routes';
import AppRoutes from './app/routes/app.routes';
import AppSuporteRoutes from './app/routes/app.suporte.routes';
import AuthRoutes from './app/routes/auth.routes';
import Notification from './firebase/Notification';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/vendor/fontawesome-free-6.2.0-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/custom.css';
import './app/assets/scss/custom.scss';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

function App() {
  const { isAuthenticated, isAuthenticatedAdmin, loggedUser } = useAuth();

  return (
    <>
      <Router>
        <Suspense fallback={loading}>
          {isAuthenticatedAdmin ? (
            <ManagerProvider>
              <AppAdminRoutes />
            </ManagerProvider>
          ) : isAuthenticated ? (
            <ManagerProvider>
              {loggedUser?.isSuporte ? (
                <AppSuporteRoutes />
              ) : (
                <AppRoutes />
              )}
            </ManagerProvider>
          ) : (
            <AuthRoutes />
          )}
        </Suspense>
      </Router>
      <Notification />
    </>
  );
}

export default App;

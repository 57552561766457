import React from 'react';

const Suporte = React.lazy(() => import('../pages/Suporte'));
const SuporteChat = React.lazy(() => import('../pages/SuporteChat'));
const SuporteEmail = React.lazy(() => import('../pages/SuporteEmail'));

const routes = [
  {
    path: '/suporte',
    Component: Suporte,
  },
  {
    path: '/suporte/chat',
    Component: SuporteChat,
  },
  {
    path: '/suporte/email',
    Component: SuporteEmail,
  },
];

export default routes;

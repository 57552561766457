import React, { useEffect, useRef, useContext } from 'react';

import NotificationComponent from '../app/components/Notification';
import { AppContext } from '../app/contexts/app';
import { useAuth } from '../app/hooks/auth';
import { requestForToken, onMessageListener } from './firebase';

function Notification() {
  const notificationRef = useRef();
  const { isAuthenticated } = useAuth();
  const { setUserHasNotifications, setUserHasMessages } = useContext(AppContext);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        await requestForToken();

        console.log('start onMessageListener');
        onMessageListener()
          .then((payload) => {
            console.log('onMessageListener Notification', payload);
            setUserHasNotifications(true);

            if (payload?.data?.title?.search('NOVA MENSAGEM')) {
              setUserHasMessages(true);
            }

            notificationRef.current.notify({
              title: payload?.data?.title,
              message: payload?.data?.body,
              color: 'warning',
            });
          })
          .catch((err) => console.log('failed: ', err));
      })();
    }
  }, [isAuthenticated]);

  return <NotificationComponent ref={notificationRef} />;
}

export default Notification;

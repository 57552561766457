/* eslint-disable default-case */
import React, { forwardRef, useImperativeHandle } from 'react';
import { ToastContainer, toast } from 'react-toastify';

function Notification(_props, ref) {
  useImperativeHandle(ref, () => ({
    notify: ({ message, color, autoDismiss = 3000 }) => {
      switch (color || 'success') {
        case 'info':
          toast.info(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: autoDismiss,
            pauseOnHover: true,
            theme: 'colored',
          });
          break;
        case 'success':
          toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: autoDismiss,
            pauseOnHover: true,
            theme: 'colored',
          });
          break;
        case 'warning':
          toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: autoDismiss,
            pauseOnHover: true,
            theme: 'colored',
          });
          break;
        case 'danger':
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: autoDismiss,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
          });
          break;
      }
    },
  }));

  return (
    <ToastContainer />
  );
}

export default forwardRef(Notification);

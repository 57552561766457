/* eslint-disable max-len */
import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const HomeCategoria = React.lazy(() => import('../pages/HomeCategoria'));
const HomeVenderRapido = React.lazy(() => import('../pages/HomeVenderRapido'));
const HomeAdvancedSearch = React.lazy(() => import('../pages/HomeAdvancedSearch'));
const Veiculo = React.lazy(() => import('../pages/Veiculo'));
const VeiculoSendMessegeSucess = React.lazy(() => import('../pages/VeiculoSendMessegeSucess'));
const Login = React.lazy(() => import('../pages/Login'));
const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));
const LoginSuporte = React.lazy(() => import('../pages/LoginSuporte'));
const SuporteSendMessage = React.lazy(() => import('../pages/SuporteSendMessage'));
const Register = React.lazy(() => import('../pages/Register'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const RecoverPassword = React.lazy(() => import('../pages/RecoverPassword'));
const TabelaFIPE = React.lazy(() => import('../pages/TabelaFIPE'));
const ReportUser = React.lazy(() => import('../pages/ReportUser'));
const ReSendVerificationEmail = React.lazy(() => import('../pages/ReSendVerificationEmail'));
const VerifiedEmail = React.lazy(() => import('../pages/VerifiedEmail'));

export default [
  { path: '/:veiculoTipoId', Component: Home },
  { path: '/:veiculoTipoId/categoria/:id', Component: HomeCategoria },
  { path: '/:veiculoTipoId/oportunidades', Component: HomeVenderRapido },
  { path: '/:veiculoTipoId/busca-avancada', Component: HomeAdvancedSearch },
  { path: '/:veiculoTipoId/veiculo/:id', Component: Veiculo },
  { path: '/:veiculoTipoId/categoria/:categoriaId/veiculo/:id/mensagem-enviada', Component: VeiculoSendMessegeSucess },
  { path: '/login', Component: Login },
  { path: '/login-admin', Component: LoginAdmin },
  { path: '/suporte', Component: LoginSuporte },
  { path: '/suporte-enviar-mensagem', Component: SuporteSendMessage },
  { path: '/cadastro', Component: Register },
  { path: '/esquecisenha', Component: ForgetPassword },
  { path: '/alterarsenha/:token', Component: RecoverPassword },
  { path: '/consultar-tabela-FIPE', Component: TabelaFIPE },
  { path: '/denunciar-usuario', Component: ReportUser },
  { path: '/reenvio-de-email-para-verificacao', Component: ReSendVerificationEmail },
  { path: '/verificar-email/:token', Component: VerifiedEmail },
  { path: '*', Component: Home },
];

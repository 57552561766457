import React from 'react';
import { Spinner } from 'reactstrap';

function Loader({ loading }) {
  if (loading === true) {
    return (
      <div
        className="bg-primary opacity-7 d-flex align-items-center justify-content-center position-fixed left-0 top-0"
        style={{
          width: '100%',
          height: '130vh',
          zIndex: 999999,
        }}
      >
        <Spinner color="light" type="grow">...</Spinner>
      </div>
    );
  }

  if (loading?.loading) {
    return (
      <div
        className="bg-primary opacity-7 d-flex flex-column align-items-center justify-content-center position-fixed left-0 top-0"
        style={{
          width: '100%',
          height: '130vh',
          zIndex: 999999,
        }}
      >
        <Spinner color="light" type="grow">...</Spinner>
        <div className="text-center text-white" style={{ fontSize: '1.5rem' }}>
          {loading?.message || 'Carregando...'}
        </div>
      </div>
    );
  }

  return null;
}

export default Loader;

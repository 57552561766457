import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import AppProvider from './app/contexts/app';
import AuthProvider from './app/hooks/auth';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <AppProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </AppProvider>,
  document.getElementById('root'),
);

serviceWorker.register();

import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import io from 'socket.io-client';

import Loader from '../components/FullScreenLoader';
import { SIDEBARCOLLAPSED, SIDEBARSHOW } from '../constants/app';
import { ID_KEY, ID_KEY_ADMIN } from '../constants/auth';
import api from '../services/api';

export const AppContext = createContext();

function AppProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [sidebarShow, setSidebarShow] = useState('opened');
  const [collapsedSidebar, setCollapsedSidebar] = useState('opened');
  const [contentHeight, setContentHeight] = useState('600');
  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(true);
  const [socketIO, setSocketIO] = useState();
  const [userHasNotifications, setUserHasNotifications] = useState(false);
  const [userHasMessages, setUserHasMessages] = useState(false);

  const toggleSidebar = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  const toggleCollapsedSidebarSidebar = useCallback(() => {
    setCollapsedSidebar((prevState) => {
      localStorage.setItem(SIDEBARCOLLAPSED, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  const onUserHasNotifications = useCallback(() => {
    (async () => {
      try {
        const { data } = await api.get('/userHasNotifications');
        setUserHasNotifications(data.hasNotifications);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onUserHasMessages = useCallback(() => {
    (async () => {
      try {
        const { data } = await api.get('/userHasMessages');
        setUserHasMessages(data.hasMessages);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    setSidebarShow(localStorage.getItem(SIDEBARSHOW) || 'opened');
    setCollapsedSidebar(localStorage.getItem(SIDEBARCOLLAPSED) || 'opened');
  }, []);

  const value = useMemo(
    () => ({
      loading,
      sidebarShow,
      collapsedSidebar,
      toggleSidebar,
      toggleCollapsedSidebarSidebar,
      navbarTogglerOpen,
      userHasNotifications,
      userHasMessages,
      contentHeight,
      setUserHasNotifications,
      setUserHasMessages,
      setNavbarTogglerOpen,
      setLoading,
      setContentHeight,
      socketIO: (reconnect = false) => {
        if (!socketIO || reconnect) {
          console.log('socketIO', socketIO);
          const userId = localStorage.getItem(ID_KEY) || localStorage.getItem(ID_KEY_ADMIN);
          console.log('userId', userId);
          const socket = io(process.env.REACT_APP_SOCKET_URL, {
            query: { userId },
          });
          setSocketIO(socket);
          return socket;
        }

        return socketIO;
      },
      onUserHasNotifications,
      onUserHasMessages,
    }),
    [
      loading,
      sidebarShow,
      collapsedSidebar,
      toggleSidebar,
      toggleCollapsedSidebarSidebar,
      navbarTogglerOpen,
      userHasNotifications,
      userHasMessages,
      socketIO,
      contentHeight,
    ],
  );

  return (
    <AppContext.Provider value={value}>
      <Loader loading={loading} />
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;

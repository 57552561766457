/* eslint-disable max-len */
// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import api from '../app/services/api';

const firebaseConfig = {
  apiKey: 'AIzaSyBLlbYtz1w8f-1ay_LngcAPyk8NTGYpro4',
  authDomain: 'barato-carro.firebaseapp.com',
  projectId: 'barato-carro',
  storageBucket: 'barato-carro.appspot.com',
  messagingSenderId: '174036673851',
  appId: '1:174036673851:web:0fa55b195c25dc2205031e',
  measurementId: 'G-QPJPNSTJYG',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

let count = 0;
export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BF3tsRtwbHiqj1GCNfurDgYf1rswujtQZ0mFYKKhZHAG0j_CrSsxM520As5mXFt4dY7mD3PUg729dDt52x_2GGc' });
    if (currentToken) {
      console.log('current token for client: ', count, currentToken);
      count += 1;
      // Perform any other neccessary action with the token
      await api.put('/userFirebaseTokens', {
        token: currentToken,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
      });
    } else {
      // Show permission request UI
      console.log(
        'No registration token available. Request permission to generate one.',
      );
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});

import React from 'react';

const Dashboard = React.lazy(() => import('../pages/DashboardAdmin'));
const Admins = React.lazy(() => import('../pages/Admins'));
const Usuarios = React.lazy(() => import('../pages/Users'));
const AdminVeiculos = React.lazy(() => import('../pages/AdminVeiculos'));
const AdminVeiculo = React.lazy(() => import('../pages/AdminVeiculo'));
const Sliders = React.lazy(() => import('../pages/Sliders'));
const SlidersMiddles = React.lazy(() => import('../pages/SlidersMiddle'));
const SlidersBottoms = React.lazy(() => import('../pages/SlidersBottom'));
const SlidersCategoria = React.lazy(() => import('../pages/SlidersCategoria'));
const VeiculoTipo = React.lazy(() => import('../pages/VeiculoTipo'));
const Categoria = React.lazy(() => import('../pages/Categoria'));
const Marcas = React.lazy(() => import('../pages/Marcas'));
const Modelos = React.lazy(() => import('../pages/Modelos'));
const Cores = React.lazy(() => import('../pages/Cores'));
const Caracteristicas = React.lazy(() => import('../pages/Caracteristicas'));
const Combustiveis = React.lazy(() => import('../pages/Combustiveis'));
const Opcionais = React.lazy(() => import('../pages/Opcionais'));
const ReportedUserVehicles = React.lazy(() => import('../pages/ReportedUserVehicles'));

const routes = [
  {
    path: '/admin/',
    name: 'Dashboard',
    Component: Dashboard,
  },
  {
    path: '/admin/administradores',
    name: 'Administradores',
    Component: Admins,
  },
  {
    path: '/admin/usuarios',
    Component: Usuarios,
  },
  {
    path: '/admin/usuarios-denunciados',
    Component: ReportedUserVehicles,
  },
  {
    path: '/admin/veiculos',
    Component: AdminVeiculos,
  },
  {
    path: '/admin/veiculos/:id',
    Component: AdminVeiculo,
  },
  {
    path: '/admin/sliders',
    Component: Sliders,
  },
  {
    path: '/admin/sliders-meio-pagina',
    Component: SlidersMiddles,
  },
  {
    path: '/admin/sliders-fim-pagina',
    Component: SlidersBottoms,
  },
  {
    path: '/admin/sliders-categorias',
    Component: SlidersCategoria,
  },
  {
    path: '/admin/veiculo-tipo',
    Component: VeiculoTipo,
  },
  {
    path: '/admin/categorias',
    Component: Categoria,
  },
  {
    path: '/admin/marcas',
    Component: Marcas,
  },
  {
    path: '/admin/modelos',
    Component: Modelos,
  },
  {
    path: '/admin/cores',
    Component: Cores,
  },
  {
    path: '/admin/caracteristicas',
    Component: Caracteristicas,
  },
  {
    path: '/admin/combustiveis',
    Component: Combustiveis,
  },
  {
    path: '/admin/opcionais',
    Component: Opcionais,
  },
];

export default routes;

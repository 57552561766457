import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
  useEffect } from 'react';

import AlertModal from '../components/AlertModal';
import Notification from '../components/Notification';
import api from '../services/api';

const ManagerContext = createContext();

function ManagerProvider({ children }) {
  const notificationRef = useRef();
  const confirmRef = useRef();
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [searchArray, setSearchArray] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const handlerError = useCallback((error, title) => {
    if (typeof error === 'string') {
      notificationRef.current.notify({
        title,
        message: error,
        color: 'danger',
      });
      return;
    }

    if (error && error.response) {
      const { message } = error.response.data;
      if (message) {
        notificationRef.current.notify({
          title,
          message,
          color: 'danger',
        });
        return;
      }
    }

    notificationRef.current.notify({
      message: title,
      color: 'danger',
    });
  }, []);

  useEffect(() => {
    setSearchArray((prevState) => {
      const filtered = prevState.filter((s) => s.field !== search.field);
      if (search.value) {
        return [...filtered, search];
      }
      return filtered;
    });
  }, [search]);

  const load = useCallback(
    (url, onSuccess, onError) => {
      setDatas([]);
      if (url) {
        (async () => {
          try {
            setLoading(true);
            let urlString = `${url}?page=${page}&limit=${limit}`;
            if (searchArray.length) {
              urlString = `${urlString}&search=${JSON.stringify(searchArray)}`;
            }
            const res = await api.get(urlString);
            // console.log(res.data.docs);
            setDatas(res.data.docs);
            setHasPrevPage(res.data.hasPrevPage);
            setHasNextPage(res.data.hasNextPage);
            setTotalPages(res.data.totalPages);
            setTotalItems(res.data.totalDocs);
            if (onSuccess) {
              onSuccess(res.data.docs);
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao carregar os dados');
          }
        })();
      }
    },
    [page, limit, searchArray, handlerError],
  );

  const store = useCallback(
    (url, data, onSuccess, onError) => {
      if (url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.post(url, data);
            setDatas((d) => [res.data, ...d]);
            notificationRef.current.notify({
              message: 'Cadastrado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess(res.data);
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao Cadastrar');
          }
        })();
      }
    },
    [handlerError],
  );

  const update = useCallback(
    (id, url, data, onSuccess, onError) => {
      if (id && url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.put(`${url}/${id}`, data);
            setDatas((ds = []) => ds.map((d) => ((d.id || d._id) === id ? res.data : d)));
            notificationRef.current.notify({
              message: 'Alterado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess(res.data);
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao alterar');
          }
        })();
      }
    },
    [handlerError],
  );

  const destroy = useCallback(
    (id, url, onSuccess, onError) => {
      if (id && url) {
        (async () => {
          try {
            // console.log('destroy', id, url);
            confirmRef.current.show({
              message: 'Tem certeza que deseja excluir?',
              confirmColor: 'danger',
              confirmText: 'Excluir',
              cancelText: 'Cancelar',
              cancelColor: 'link',
              onConfirm: async () => {
                try {
                  setLoading(true);
                  await api.delete(`${url}/${id}`);
                  setDatas((d) => d.filter((value) => (value.id || value._id) !== id));
                  notificationRef.current.notify({
                    message: 'Excluido com sucesso',
                  });
                  if (onSuccess) {
                    onSuccess();
                  }
                  confirmRef.current.close();
                  setLoading(false);
                } catch (error) {
                  if (onError) {
                    onError(error);
                  }
                  setLoading(false);
                  handlerError(error, 'Falha ao excluir');
                }
              },
            });
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao excluir');
          }
        })();
      }
    },
    [handlerError],
  );

  const value = useMemo(
    () => ({
      loading,
      load,
      datas,
      setDatas,
      page,
      setPage,
      limit,
      setLimit,
      setSearch,
      setSearchArray,
      hasNextPage,
      hasPrevPage,
      totalPages,
      totalItems,
      store,
      update,
      destroy,
    }),
    [
      loading,
      load,
      datas,
      setDatas,
      page,
      setPage,
      limit,
      setLimit,
      setSearch,
      setSearchArray,
      hasNextPage,
      hasPrevPage,
      totalPages,
      totalItems,
      store,
      update,
      destroy,
    ],
  );

  return (
    <ManagerContext.Provider value={value}>
      <AlertModal ref={confirmRef} />
      <Notification ref={notificationRef} />
      {children}
    </ManagerContext.Provider>
  );
}

export const useManager = () => {
  const context = useContext(ManagerContext);

  if (!context) {
    throw new Error('useManager must be used within a ManagerProvider');
  }

  return context;
};

export default ManagerProvider;
